.slide-1 {
    -webkit-mask-image: linear-gradient(to right, transparent 31%, black 35%, black 64%, transparent 68%);
    -webkit-mask-size: 300% 100%;
    mask-image: linear-gradient(to right, transparent 31%, black 35%, black 64%, transparent 68%);
    mask-size: 300% 100%;
    animation: wipe-n-zoom 6s linear 2s 1 forwards;

    img {
        width: 85vw;
    }

    h4 {
        text-transform: uppercase;
        text-align: center;
        font: normal normal normal 2.6em/1em Montserrat;
        letter-spacing: .3em;
        color: $c-white;
        padding: .3em;
    }

    h5 {
        text-transform: uppercase;
        text-align: center;
        font: normal normal normal 2em/1em Montserrat;
        letter-spacing: .3em;
        color: $c-white;
        padding: .3em;
    }
}

@include breakpoint(tabletPortraitUp) {
    .slide-1 {
        img {
            width: 65vw;
        }

        h4 {
            font-size: 2em;
            padding: 1.5em;
        }
    }
}
