footer {
    .row {
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        > div {
            display: flex;
            flex-direction: column;
            align-content: flex-start;

            > * {
                align-self: flex-start;
            }
        }
    }
}

@include breakpoint(tabletPortraitUp) {
    footer {
        .row {
           flex-direction: row;
        }
    }
}