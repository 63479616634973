.slide {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: center;
    transition: visibility .5s, opacity .5s ease;
}

.slide.hidden {
    visibility: hidden;
    opacity: 0;
}

.slide.desktop-only {
    display: none;
}

@include breakpoint(tabletPortraitUp) {
    .slide.desktop-only {
        display: flex;
    }
    .slide.mobile-only {
        display: none;
    }
}
