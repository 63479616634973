/** Blocks **/

@mixin absolute-block($top: 0, $right: 0, $bottom: 0, $left: 0, $width: 100%, $height: 100%) {
  position: absolute;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
  width: $width;
  height: $height;
}

@mixin fixed-block($top: 0, $right: 0, $bottom: 0, $left: 0, $width: 100%, $height: 100%) {
  position: fixed;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
  width: $width;
  height: $height;
}