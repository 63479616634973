@import "https://fonts.googleapis.com/css2?family=Montserrat&display=swap";
@keyframes wipe-n-zoom {
  0% {
    transform: scale(1);
    -webkit-mask-position: 100%;
    mask-position: 100%;
  }

  30% {
    -webkit-mask-position: center;
    mask-position: center;
  }

  70% {
    -webkit-mask-position: center;
    mask-position: center;
  }

  100% {
    transform: scale(1.1);
    -webkit-mask-position: 0;
    mask-position: 0;
  }
}

@keyframes wipe-in {
  0% {
    -webkit-mask-position: 100%;
    mask-position: 100%;
  }

  30% {
    -webkit-mask-position: center;
    mask-position: center;
  }

  70% {
    -webkit-mask-position: center;
    mask-position: center;
  }

  100% {
    -webkit-mask-position: center;
    mask-position: center;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@media print {
  *, :before, :after {
    color: #000 !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    text-shadow: none !important;
    background: none !important;
  }

  a, a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: " (" attr(href) ")";
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  a[href^="#"]:after, a[href^="javascript:"]:after {
    content: "";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre, blockquote {
    page-break-inside: avoid;
    border: 1px solid #999;
  }

  thead {
    display: table-header-group;
  }

  tr, img {
    page-break-inside: avoid;
  }

  p, h2, h3 {
    orphans: 3;
    widows: 3;
  }

  h2, h3 {
    page-break-after: avoid;
  }
}

*, :before, :after {
  box-sizing: border-box;
}

:before, :after {
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
  vertical-align: inherit;
}

html {
  cursor: default;
  tab-size: 4;
  -webkit-tap-highlight-color: transparent;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  word-break: break-word;
  line-height: 1.5;
}

body {
  margin: 0;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

dl dl, dl ol, dl ul, ol dl, ul dl, ol ol, ol ul, ul ol, ul ul {
  margin: 0;
}

hr {
  height: 0;
  overflow: visible;
}

main {
  display: block;
}

nav ol, nav ul {
  padding: 0;
  list-style: none;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  background-color: #0000;
}

abbr[title] {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

audio, canvas, iframe, img, svg, video {
  vertical-align: middle;
}

audio, video {
  display: inline-block;
}

audio:not([controls]) {
  height: 0;
  display: none;
}

iframe {
  border-style: none;
}

img {
  max-width: 100%;
  border-style: none;
}

svg:not([fill]) {
  fill: currentColor;
}

svg:not(:root) {
  overflow: hidden;
}

table {
  border-collapse: collapse;
}

button, input, select {
  margin: 0;
}

button {
  text-transform: none;
  overflow: visible;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

fieldset {
  border: 1px solid #a0a0a0;
  padding: .35em .75em .625em;
}

input {
  overflow: visible;
}

legend {
  color: inherit;
  max-width: 100%;
  white-space: normal;
  display: table;
}

progress {
  vertical-align: baseline;
  display: inline-block;
}

select {
  text-transform: none;
}

textarea {
  resize: vertical;
  margin: 0;
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  padding: 0;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

::-webkit-input-placeholder {
  color: inherit;
  opacity: .54;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

:-moz-focusring {
  outline: 1px dotted ButtonText;
}

:-moz-ui-invalid {
  box-shadow: none;
}

details {
  display: block;
}

dialog {
  color: #000;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
  background-color: #fff;
  border: solid;
  margin: auto;
  padding: 1em;
  display: block;
  position: absolute;
  left: 0;
  right: 0;
}

dialog:not([open]) {
  display: none;
}

summary {
  display: list-item;
}

canvas {
  display: inline-block;
}

template {
  display: none;
}

a, area, button, input, label, select, summary, textarea, [tabindex] {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

[hidden] {
  display: none;
}

[aria-busy="true"] {
  cursor: progress;
}

[aria-controls] {
  cursor: pointer;
}

[aria-disabled="true"], [disabled] {
  cursor: not-allowed;
}

[aria-hidden="false"][hidden] {
  display: initial;
}

[aria-hidden="false"][hidden]:not(:focus) {
  clip: rect(0, 0, 0, 0);
  position: absolute;
}

body {
  max-width: 100%;
  overflow-x: hidden;
}

@media (min-width: 0) {
  html {
    font-size: .35em;
  }
}

@media (min-width: 600px) {
  html {
    font-size: .5em;
  }
}

@media (min-width: 900px) {
  html {
    font-size: .7em;
  }
}

@media (min-width: 1200px) {
  html {
    font-size: 1em;
  }
}

@media (min-width: 1600px) {
  html {
    font-size: 1.5em;
  }
}

a {
  opacity: 1;
  transition: opacity .3s;
}

a:hover {
  opacity: .6;
}

.background {
  height: 100vh;
  width: 100%;
  object-fit: cover;
  z-index: -100;
  position: fixed;
  inset: 0;
}

.button {
  height: 2em;
  width: 10em;
  border: 1px solid #fff;
  border-radius: 1em;
  justify-content: center;
  align-items: center;
  margin: .8em 0;
  font: 1.2em Montserrat;
  display: flex;
}

.header-buttons {
  z-index: 100;
  flex-direction: row-reverse;
  justify-content: space-between;
  display: none;
  position: absolute;
  top: 1.5em;
  left: 4em;
  right: 4em;
}

@media (min-width: 600px) {
  .header-buttons {
    font-size: .7em;
    display: flex;
    top: calc(3em - 12px);
  }
}

@media (min-width: 1200px) {
  .header-buttons {
    font-size: .7em;
    display: flex;
    top: calc(4em - 12px);
  }
}

.header-buttons a {
  color: #fff;
  text-decoration: none;
}

.arrow-buttons {
  font-size: 2.5em;
  position: absolute;
  bottom: 50px;
}

@media (min-width: 600px) {
  .arrow-buttons {
    display: none;
  }
}

.arrow-buttons a {
  color: #fff;
  text-decoration: none;
}

@media (max-height: 600px) {
  .arrow-buttons {
    font-size: 2em;
  }
}

.footer-buttons {
  padding-top: 1em;
  font-size: 2.5em;
}

@media (min-width: 600px) {
  .footer-buttons {
    font-size: .8em;
  }
}

.main {
  height: 100vh;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 3em 0;
  display: flex;
}

.slide {
  flex-direction: column;
  justify-content: center;
  align-self: center;
  align-items: center;
  transition: visibility .5s, opacity .5s;
  display: flex;
  position: absolute;
  inset: 0;
}

.slide.hidden {
  visibility: hidden;
  opacity: 0;
}

.slide.desktop-only {
  display: none;
}

@media (min-width: 600px) {
  .slide.desktop-only {
    display: flex;
  }

  .slide.mobile-only {
    display: none;
  }
}

footer .row {
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

footer .row > div {
  flex-direction: column;
  align-content: flex-start;
  display: flex;
}

footer .row > div > * {
  align-self: flex-start;
}

@media (min-width: 600px) {
  footer .row {
    flex-direction: row;
  }
}

footer {
  color: #f8f8f8;
  background-color: #2b2b2b;
  padding-bottom: 1em;
  font-size: .9em;
}

footer .row {
  padding: 2em 3em;
}

footer .row > * {
  margin: 0 1em;
}

footer h3 {
  color: #fff;
  padding-top: 1.5em;
  font: 4em Montserrat;
}

footer address, footer span {
  font: 3em / 1.5em Montserrat;
}

footer a {
  color: #f8f8f8;
  cursor: pointer;
  text-decoration: none;
}

footer .col3 span {
  margin-bottom: .6em;
}

footer .footer-contacts {
  flex-direction: column;
  gap: 3em;
  display: flex;
}

footer .footer-contacts > div {
  flex-direction: column;
  display: flex;
}

footer .salboy {
  width: 50vw;
  margin-top: .5em;
  margin-bottom: 1em;
}

footer .obi {
  width: 11.5vw;
  margin-bottom: 1em;
}

footer .knight-frank {
  width: 30vw;
  margin-bottom: 1em;
}

@media (min-width: 600px) {
  footer .socials div {
    margin-left: auto;
  }
}

footer .socials img {
  width: 10vw;
  padding-right: 3.5vw;
}

footer .copyright {
  color: #9a9a9a;
  padding: 2.8em 1.5em 1em;
  font: 3em Montserrat;
}

@media (min-width: 600px) {
  footer .row {
    padding: 2em 4em;
  }

  footer h3 {
    padding-top: unset;
    padding-bottom: 1em;
    font-size: 1.1em;
  }

  footer address, footer span {
    font-size: .9em;
  }

  footer .footer-contacts {
    flex-direction: row;
  }

  footer .socials h3 {
    align-self: flex-end;
  }

  footer .salboy {
    height: 1.8em;
    width: auto;
  }

  footer .obi, footer .knight-frank {
    height: 2.2em;
    width: auto;
  }

  footer .socials img {
    padding-right: unset;
    width: 2em;
    padding-left: .6em;
  }

  footer .copyright {
    padding: 2em 1.5em 2em 4.5em;
    font-size: .9em;
  }
}

@media (min-width: 1200px) {
  footer {
    font-size: 1em;
  }
}

@media (min-width: 1600px) {
  footer {
    font-size: .833333em;
  }
}

.gallery {
  scroll-snap-type: x mandatory;
  scrollbar-width: 0;
  scroll-behavior: smooth;
  display: flex;
  overflow: auto;
  -webkit-mask-image: linear-gradient(1deg, #000, #000), url("arrow-mask-left.4ab5d711.png"), url("arrow-mask-right.0bfd1be1.png");
  mask-image: linear-gradient(1deg, #000, #000), url("arrow-mask-left.4ab5d711.png"), url("arrow-mask-right.0bfd1be1.png");
  -webkit-mask-position: center, 0%, 100%;
  mask-position: center, 0%, 100%;
  -webkit-mask-size: 100% 100%, 23px 62px, 23px 62px;
  mask-size: 100% 100%, 23px 62px, 23px 62px;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

@media (min-width: 600px) {
  .gallery {
    -webkit-mask-size: 100% 100%, 39px 103px, 39px 103px;
    mask-size: 100% 100%, 39px 103px, 39px 103px;
  }
}

@media (min-width: 1200px) {
  .gallery {
    -webkit-mask-size: 100% 100%, 78px 206px, 78px 206px;
    mask-size: 100% 100%, 78px 206px, 78px 206px;
  }
}

.gallery-wrapper {
  width: 100%;
  aspect-ratio: 16 / 9;
  position: relative;
}

@media (min-width: 600px) {
  .gallery-wrapper {
    height: 100vh;
    aspect-ratio: unset;
  }
}

.gallery::-webkit-scrollbar {
  display: none;
}

.gallery img {
  width: 100%;
  aspect-ratio: 16 / 9;
  object-fit: cover;
  scroll-snap-align: start;
  flex-shrink: 0;
}

@media (min-width: 600px) {
  .gallery img {
    height: 100vh;
    aspect-ratio: unset;
  }
}

.gallery-arrow-left {
  width: 16px;
  height: 8px;
  z-index: 5;
  background-image: url("arrow-down.792c0fef.svg");
  background-repeat: no-repeat;
  background-size: contain;
  transition: all .25s ease-in-out;
  display: block;
  position: absolute;
  top: calc(50% - 4px);
  left: 2px;
  transform: rotate(90deg);
}

@media (min-width: 600px) {
  .gallery-arrow-left {
    width: 25px;
    height: 13px;
    top: calc(50% - 6.5px);
    left: 5px;
  }
}

@media (min-width: 1200px) {
  .gallery-arrow-left {
    width: 50px;
    height: 26px;
    top: calc(50% - 13px);
    left: 10px;
  }
}

.gallery-arrow-left:hover {
  cursor: pointer;
  opacity: .6;
}

.gallery-arrow-right {
  width: 16px;
  height: 8px;
  z-index: 5;
  background-image: url("arrow-down.792c0fef.svg");
  background-repeat: no-repeat;
  background-size: contain;
  transition: all .25s ease-in-out;
  display: block;
  position: absolute;
  top: calc(50% - 4px);
  right: 2px;
  transform: rotate(-90deg);
}

@media (min-width: 600px) {
  .gallery-arrow-right {
    width: 25px;
    height: 13px;
    top: calc(50% - 6.5px);
    right: 5px;
  }
}

@media (min-width: 1200px) {
  .gallery-arrow-right {
    width: 50px;
    height: 26px;
    top: calc(50% - 13px);
    right: 10px;
  }
}

.gallery-arrow-right:hover {
  cursor: pointer;
  opacity: .6;
}

header {
  color: #fff;
}

header img {
  width: 50vw;
}

@media (min-width: 600px) {
  header img {
    width: 20vw;
  }
}

.slide-1 {
  animation: wipe-n-zoom 6s linear 2s forwards;
  -webkit-mask-image: linear-gradient(to right, #0000 31%, #000 35% 64%, #0000 68%);
  mask-image: linear-gradient(to right, #0000 31%, #000 35% 64%, #0000 68%);
  -webkit-mask-size: 300% 100%;
  mask-size: 300% 100%;
}

.slide-1 img {
  width: 85vw;
}

.slide-1 h4 {
  text-transform: uppercase;
  text-align: center;
  letter-spacing: .3em;
  color: #fff;
  padding: .3em;
  font: 2.6em / 1em Montserrat;
}

.slide-1 h5 {
  text-transform: uppercase;
  text-align: center;
  letter-spacing: .3em;
  color: #fff;
  padding: .3em;
  font: 2em / 1em Montserrat;
}

@media (min-width: 600px) {
  .slide-1 img {
    width: 65vw;
  }

  .slide-1 h4 {
    padding: 1.5em;
    font-size: 2em;
  }
}

.slide-2 {
  animation: wipe-n-zoom 6.3s linear 7.7s forwards;
  -webkit-mask-image: linear-gradient(to right, #0000 31%, #000 35% 64%, #0000 68%);
  mask-image: linear-gradient(to right, #0000 31%, #000 35% 64%, #0000 68%);
  -webkit-mask-size: 300% 100%;
  mask-size: 300% 100%;
}

.slide-2 img {
  width: 80vw;
}

@media (min-width: 600px) {
  .slide-2 img {
    width: 45vw;
  }
}

.slide-3 p {
  text-transform: uppercase;
  text-align: center;
  letter-spacing: .2em;
  color: #fff;
  white-space: nowrap;
  width: 100%;
  opacity: 0;
  margin: 0;
  padding: 0;
  font: 4vw / 2.3em Montserrat;
  overflow: hidden;
}

.slide-3 p span {
  opacity: 0;
  animation: fade-in 50ms linear forwards;
}

.slide-3 p:nth-of-type(1) {
  animation-delay: 13.2s;
}

.slide-3 p:nth-of-type(2) {
  animation-delay: 14s;
}

.slide-3 p:nth-of-type(3) {
  animation-delay: 15.1s;
}

.slide-3 p:nth-of-type(4) {
  animation-delay: 16.4s;
}

@media (min-width: 600px) {
  .slide-3 {
    animation-delay: 18.5s;
  }

  .slide-3 p {
    font-size: 2.5em;
  }

  .slide-3 p:nth-of-type(1) {
    animation-delay: 13.2s;
  }

  .slide-3 p:nth-of-type(2) {
    animation-delay: 14.4s;
  }

  .slide-3 p:nth-of-type(3) {
    animation-delay: 15.5s;
  }
}

@media (min-width: 1600px) {
  .slide-3 p {
    font-size: 2em;
  }
}

#scroll_down_arrow {
  width: 40px;
  z-index: 10;
}

@media (min-width: 600px) {
  #scroll_down_arrow {
    width: auto;
  }
}

/*# sourceMappingURL=index.2cc659dd.css.map */
