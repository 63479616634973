/** Transitions
*
* Actual transitions and animations go into this file
**/

@keyframes wipe-n-zoom {
    0% {
        -webkit-mask-position: right;
        mask-position: right;
        transform: scale(1);
    }
    30% {
        -webkit-mask-position: center;
        mask-position: center;
    }
    70% {
        -webkit-mask-position: center;
        mask-position: center;
    }
    100% {
        -webkit-mask-position: left;
        mask-position: left;
        transform: scale(1.1);
    }
}

@keyframes wipe-in {
    0% {
        -webkit-mask-position: right;
        mask-position: right;
    }
    30% {
        -webkit-mask-position: center;
        mask-position: center;
    }
    70% {
        -webkit-mask-position: center;
        mask-position: center;
    }
    100% {
        -webkit-mask-position: center;
        mask-position: center;
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fade-out {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
