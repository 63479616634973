@charset 'utf-8';

/**
 * The structure is based on ITCSS with custom modifications.
 *
 * This is where all of the stylesheets are compiled.
 * They are processed in the order they are imported
 * to ensure the consistent increase of specificity.
 *
 *    ITCSS Methodology: Inverted Triangle CSS
 *
 *    ****************   1. Settings
 *     **************    2. Tools
 *      ************     3. Generic
 *       **********      4. Objects
 *        ********       5. Elements
 *         ******        6. Components
 *          ****         7. Blocks
 *           **          8. Utilities
 *
 * 1. Settings
 *    Global configuration and variables.
 *    Breakpoints, colors, spacing, utilities etc.
 *
 * 2. Tools
 *    Functions and mixins.
 *
 * 3. Generic
 *    Ground zero styles. No classes.
 *
 * 4. Objects
 *    Common non-cosmetic structural design patterns.
 *    Containers, rows, grids, colums etc.
 *
 * 5. Elements
 *    Unclassed (bare) HTML element.b
 *    H1, Ul, A etc.
 *
 * 6. Components
 *    Specific cosmetic elements of UI.
 *    Buttons, forms etc.
 *
 * 7. Blocks
 *    Multi-part components.
 *    Navbar, footer etc.
 *
 * 8. Templates
 *    Template/page specific styles
 *
 * 9. Utilities
 *    Helpers and overrides.
 */

// @import '1-settings/**/*.scss';
// @import '2-tools/**/*.scss';
// @import '3-generic/**/*.scss';
// @import '4-elements/**/*.scss';
// @import '5-objects/**/*.scss';
// @import '6-components/**/*.scss';
// @import '7-blocks/**/*.scss';
// @import '8-templates/**/*.scss';
// @import '9-utilities/**/*.scss';

// @import 'shame.scss';




@import '1-settings/breakpoints';
@import '1-settings/colors';
@import '1-settings/spacing';
@import '1-settings/typography';
@import '2-tools/absolute-block';
@import '2-tools/breakpoints';
@import '2-tools/transition';
@import '3-generic/animations';
@import '3-generic/font-face';
@import '3-generic/print';
@import '3-generic/sanitize';
@import '4-elements/document';
@import '4-elements/form';
@import '4-elements/links';

@import '5-objects/background';
@import '5-objects/button';
@import '5-objects/main';
@import '5-objects/slides';
@import '5-objects/footer';
@import '8-templates/footer';
@import '8-templates/gallery';
@import '8-templates/header';
@import '8-templates/slide-1';
@import '8-templates/slide-2';
@import '8-templates/slide-3';
@import '8-templates/scroll_down_arrow';
//  @import '5-objects/container';
//  @import '5-objects/modal';
//  @import '6-components/container';
//  @import '6-components/buttons';
//  @import '6-components/card';
//  @import '7-blocks/footer';
//  @import '8-templates/parallax';
//  @import '8-templates/sponsor';
//  @import '8-templates/info';
//  @import '8-templates/aboutwtx';
//  @import '8-templates/social';
//  @import '8-templates/policies';

@import 'shame.scss';
