header {
    color: $c-white;

    img {
        width: 50vw;
    }
}

@include breakpoint(tabletPortraitUp) {
    header {
        img {
            width: 20vw;
        }
    }
}
