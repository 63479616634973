/**
* Breakpoints
* Based on https://www.freecodecamp.org/news/the-100-correct-way-to-do-css-breakpoints-88d6a5ba1862/
*
* Define media query breakpoints
* we're defining a mobile value as 0px to be used as a default value in mixins using the breakpoint list
**/


$grid-breakpoints: (
  mobile            : 0px,
  tabletPortraitUp  : 600px,
  tabletLandscapeUp : 900px,
  desktopUp         : 1200px,
  bigDesktopUp      : 1600px,
) !default;

$breakpoints: (
  'mobile'            : 0,
  'tabletPortraitUp'  : 600,
  'tabletLandscapeUp' : 900,
  'desktopUp'         : 1200,
  'bigDesktopUp'      : 1600,
) !default;