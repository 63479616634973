/** Colors **/

$c-white: #FFFFFF;
$c-black: #000000;
$c-gray: #B2BCBE;

$c-brand: #1ABC9C;
$c-secondary: #F1C40F;

$c-footer: #2B2B2B;
$c-footer-text: #f8f8f8;
$c-copyright: #9A9A9A;
