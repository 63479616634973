/**
* Breakpoints
*
* use like:
* @include breakpoint(tabletLandscapeUp){
*   ...
* }
**/

@mixin breakpoint($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
      @media (min-width: map-get($breakpoints, $breakpoint) + 'px') {
          @content;
      }
  }
  @else {
      @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
      + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}