/** Document defaults (html, body) **/

html {

}

body {
    overflow-x: hidden;
    max-width: 100%;
}

@include breakpoint(mobile) {
    html {
        font-size: .35em;
    }
}
@include breakpoint(tabletPortraitUp) {
    html {
        font-size: .5em;
    }
}
@include breakpoint(tabletLandscapeUp) {
    html {
        font-size: .7em;
    }
}
@include breakpoint(desktopUp) {
    html {
        font-size: 1em;
    }
}
@include breakpoint(bigDesktopUp) {
    html {
        font-size: 1.5em;
    }
}
