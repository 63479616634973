.slide-2 {
    -webkit-mask-image: linear-gradient(to right, transparent 31%, black 35%, black 64%, transparent 68%);
    -webkit-mask-size: 300% 100%;
    mask-image: linear-gradient(to right, transparent 31%, black 35%, black 64%, transparent 68%);
    mask-size: 300% 100%;
    animation: wipe-n-zoom 6.3s linear 7.7s 1 forwards;

    img {
        width: 80vw;
    }
}

@include breakpoint(tabletPortraitUp) {
    .slide-2 {
        img {
            width: 45vw;
        }
    }
}
