.gallery {
  display: flex;
  overflow: auto;
  scroll-snap-type: x mandatory;
  scrollbar-width: 0;
  scroll-behavior: smooth;
  mask-image: linear-gradient(1deg, black, black), url('../../public/arrow-mask-left.png'), url('../../public/arrow-mask-right.png');
  mask-position: center center, 0% center, 100% center;
  mask-repeat: no-repeat;
  mask-composite: exclude;
  mask-size: 100% 100%, 23px 62px, 23px 62px;
  @include breakpoint(tabletPortraitUp) {
    mask-size: 100% 100%, 39px 103px, 39px 103px;
  }
  @include breakpoint(desktopUp) {
    mask-size: 100% 100%, 78px 206px, 78px 206px;
  }

  &-wrapper {
    width: 100%;
    aspect-ratio: 16 / 9;
    position: relative;
    @include breakpoint(tabletPortraitUp) {
      height: 100vh;
      aspect-ratio: unset;
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }

  img {
    flex-shrink: 0;
    width: 100%;
    aspect-ratio: 16 / 9;
    object-fit: cover;
    scroll-snap-align: start;
    @include breakpoint(tabletPortraitUp) {
      height: 100vh;
      aspect-ratio: unset;
    }
  }

  &-arrow-left {
    position: absolute;
    display: block;
    width: 16px;
    height: 8px;
    top: calc(50% - 8px / 2);
    left: 2px;
    transform: rotate(90deg);
    background-image: url('../../public/arrow-down.svg');
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 5;
    @include transition;
    @include breakpoint(tabletPortraitUp) {
      width: 25px;
      height: 13px;
      top: calc(50% - 13px / 2);
      left: 5px;
    }
    @include breakpoint(desktopUp) {
      width: 50px;
      height: 26px;
      top: calc(50% - 26px / 2);
      left: 10px;
    }

    &:hover {
      cursor: pointer;
      opacity: .6;
    }
  }

  &-arrow-right {
    position: absolute;
    display: block;
    width: 16px;
    height: 8px;
    top: calc(50% - 8px / 2);
    right: 2px;
    transform: rotate(-90deg);
    background-image: url('../../public/arrow-down.svg');
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 5;
    @include transition;
    @include breakpoint(tabletPortraitUp) {
      width: 25px;
      height: 13px;
      top: calc(50% - 13px / 2);
      right: 5px;
    }
    @include breakpoint(desktopUp) {
      width: 50px;
      height: 26px;
      top: calc(50% - 26px / 2);
      right: 10px;
    }

    &:hover {
      cursor: pointer;
      opacity: .6;
    }
  }
}
