.button {
  font: 1.2em Montserrat;

  border: 1px solid $c-white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2em;
  width: 10em;
  margin: .8em 0;
  border-radius: 1em;
}

.header-buttons {
  position: absolute;
  top: 1.5em;
  right: 4em;
  left: 4em;
  display: none;
  justify-content: space-between;
  flex-direction: row-reverse;
  z-index: 100;
  @include breakpoint(tabletPortraitUp) {
    display: flex;
    top: calc(3em - 12px);
    font-size: .7em;
  }
  @include breakpoint(desktopUp) {
    display: flex;
    top: calc(4em - 12px);
    font-size: .7em;
  }

  a {
    color: $c-white;
    text-decoration: none;
  }
}

.arrow-buttons {
  position: absolute;
  bottom: 50px;
  font-size: 2.5em;
  @include breakpoint(tabletPortraitUp) {
    display: none;
  }

  a {
    color: $c-white;
    text-decoration: none;
  }

  @media (max-height: '600px') {
    font-size: 2em;
  }
}

.footer-buttons {
  padding-top: 1em;
  font-size: 2.5em;
  @include breakpoint(tabletPortraitUp) {
    font-size: .8em;
  }
}
