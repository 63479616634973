
footer {
    background-color: $c-footer;
    color: $c-footer-text;
    padding-bottom: 1em;
    font-size: .9em;

    .row {
        padding: 2em 3em;

        & > * {
            margin: 0 1em;
        }
    }

    h3 {
        font: 4em Montserrat;
        color: $c-white;
        padding-top: 1.5em;
    }

    address, span {
        font: 3em/1.5em Montserrat;
    }

    a {
        color: $c-footer-text;
        text-decoration: none;
        cursor: pointer;
    }

    .col3 {
        span {
            margin-bottom: .6em;
        }
    }

    .footer-contacts {
        display: flex;
        flex-direction: column;
        gap: 3em;

        & > div {
            display: flex;
            flex-direction: column;
        }
    }

    .salboy {
        width: 50vw;
        margin-top: .5em;
        margin-bottom: 1em;
    }

    .obi {
        width: 11.5vw;
        margin-bottom: 1em;
    }

    .knight-frank {
        width: 30vw;
        margin-bottom: 1em;
    }

    .socials {
        div {
            @include breakpoint(tabletPortraitUp) {
                margin-left: auto;
            }
        }

        img {
            padding-right: 3.5vw;
            width: 10vw;
        }
    }

    .copyright {
        font: 3em Montserrat;
        color: $c-copyright;
        padding: 2.8em 1.5em 1em 1.5em;
    }
}

@include breakpoint(tabletPortraitUp) {
    footer {
        .row {
            padding: 2em 4em;
        }

        h3 {
            font-size: 1.1em;
            padding-top: unset;
            padding-bottom: 1em;
        }

        address, span {
            font-size: .9em;
        }


        .footer-contacts {
            flex-direction: row;
        }

        .socials {
            h3 {
              align-self: flex-end;
            }
        }

        .salboy {
            height: 1.8em;
            width: auto;
        }

        .obi {
            height: 2.2em;
            width: auto;
        }

        .knight-frank {
            height: 2.2em;
            width: auto;
        }

        .socials {
            img {
                padding-right: unset;
                padding-left: .6em;
                width: 2em;
            }
        }

        .copyright {
            font-size: .9em;
            padding: 2em 1.5em 2em 4.5em;
        }
    }
}

@include breakpoint(desktopUp) {
    footer {
        font-size: 1em;
    }
}


@include breakpoint(bigDesktopUp) {
    footer {
        font-size: .8333334em;
    }
}
