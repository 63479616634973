#scroll_down_arrow {
    width: 40px;
    z-index: 10;
}

@include breakpoint(tabletPortraitUp) {
    #scroll_down_arrow {
        width: auto;
    }
}
