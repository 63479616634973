.slide-3 {
    p {
        text-transform: uppercase;
        text-align: center;
        font: normal normal normal 2.5em/2.3em Montserrat;
        font-size: 4vw;
        letter-spacing: .2em;
        padding: 0;
        margin: 0;
        color: $c-white;
        white-space: nowrap;
        overflow: hidden;
        width: 100%;
        opacity: 0;  // this gets set back to 1 in javascript

        span {
            opacity: 0;
            animation: fade-in .05s linear 1 forwards;
        }
    }

    // those delay times are used in js
    p:nth-of-type(1) {
        animation-delay: 13.2s;
    }
    p:nth-of-type(2) {
        animation-delay: 14s;
    }
    p:nth-of-type(3) {
        animation-delay: 15.1s;
    }
    p:nth-of-type(4) {
        animation-delay: 16.4s;
    }
}

@include breakpoint(tabletPortraitUp) {
    .slide-3 {
        animation-delay: 18.5s;

        p {
            font-size: 2.5em;
        }

        p:nth-of-type(1) {
            animation-delay: 13.2s;
        }
        p:nth-of-type(2) {
            animation-delay: 14.4s;
        }
        p:nth-of-type(3) {
            animation-delay: 15.5s;
        }
    }
}

@include breakpoint(bigDesktopUp) {
    .slide-3 {
        p {
            font-size: 2em;
        }
    }
}
